import React, { useState } from "react";
import "./AmazonTranscribe.scss";
import { Form } from "react-bootstrap";
import { Loader, PrimaryButton } from "@/components";
import { use } from "react";
import useAmazonTranscribe from "./useAmazonTranscribe";
import Transcript from "../SessionDetails/components/Transcript";

function AmazonTranscribe(props) {
  const {
    providerSessionId,
    setProviderSessionId,
    deepgramTranscriptData,
    amazonTranscribeTranscriptData,
    deepgramTranscriptLoading,
    amazonTranscribeTranscriptLoading,
    getSessionTranscript,
    sessionDetailsData,
    sessionDetailsLoading,
  } = useAmazonTranscribe();

  return (
    <div className="amazon-transcribe-page">
      <div className="session-link-container">
        <Form.Group className="custom-input-field">
          <Form.Control
            type="text"
            placeholder="Provider Session ID"
            value={providerSessionId}
            onChange={e => setProviderSessionId(e.target.value)}
            disabled={
              !!deepgramTranscriptLoading ||
              !!amazonTranscribeTranscriptLoading ||
              !!sessionDetailsLoading
            }
          />
        </Form.Group>
        <PrimaryButton
          disabled={
            !!deepgramTranscriptLoading ||
            !!amazonTranscribeTranscriptLoading ||
            !!sessionDetailsLoading ||
            !providerSessionId
          }
          onClick={getSessionTranscript}
        >
          Submit
        </PrimaryButton>
      </div>
      <Loader
        loading={
          !!deepgramTranscriptLoading ||
          !!amazonTranscribeTranscriptLoading ||
          !!sessionDetailsLoading
        }
      >
        <div className="compare-transcript-container">
          <div className="compare-transcript-item">
            <label>Deepgram</label>
            <Transcript
              data={deepgramTranscriptData}
              loading={deepgramTranscriptLoading}
              sessionCategory={sessionDetailsData?.category}
              sessionData={sessionDetailsData}
              sessionId={sessionDetailsData?.id}
              compareTranscript={true}
            />
          </div>
          <div className="compare-transcript-item">
            <label>Amazon Transcribe</label>
            <Transcript
              data={amazonTranscribeTranscriptData}
              loading={amazonTranscribeTranscriptLoading}
              sessionCategory={sessionDetailsData?.category}
              sessionData={sessionDetailsData}
              sessionId={sessionDetailsData?.id}
              compareTranscript={true}
            />
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default AmazonTranscribe;
