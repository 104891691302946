import React from "react";
import { RichTextEditor } from "@/components/RichTextEditor";
import { getLocalStorage } from "@/services/utils";
import { marked } from "marked";
import "./ThemesConflicts.scss";
import GroupDynamics from "../GroupDynamics";

let storedGroupNotesData = getLocalStorage("groupNotesData");

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

function ThemesConflicts(props) {
  // const groupNotesData = storedGroupNotesData?.[props.sessionId] || {};
  const groupNotesData = props.soapNotesJson?.groupExtendedNotes || {};
  let themesData = groupNotesData?.themesAndTopics
    ? convertMarkdownToHtml(groupNotesData.themesAndTopics) || groupNotesData.themesAndTopics
    : "";
  let conflictsData = groupNotesData?.conflictsOrChallenges
    ? convertMarkdownToHtml(groupNotesData.conflictsOrChallenges) ||
      groupNotesData.conflictsOrChallenges
    : "";
  let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
    ? props.speakerRecognition?.data?.[props.sessionId][
        Object.keys(props.speakerRecognition.data[props.sessionId])[0]
      ]
    : null;
  const getSpeakerName = speakerLabel => {
    let speakerName = speakerLabel;
    let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
      ? props.speakerRecognition?.data?.[props.sessionId][
          Object.keys(props.speakerRecognition.data[props.sessionId])[0]
        ]
      : null;
    if (!speakerRecognitionData) {
      return speakerName;
    }
    let speakerData = speakerRecognitionData[speakerLabel] || null;
    if (!speakerData) {
      return speakerName;
    }
    if (speakerData.predictedLabel?.toLowerCase() === "provider") {
      if (props.sessionData?.practitionerFirstName) {
        speakerName = `${props.sessionData.practitionerFirstName || ""}${
          props.sessionData.practitionerLastName ? " " : ""
        }${props.sessionData.practitionerLastName || ""}`;
        return speakerName;
      }
    }
    let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
    let speakerPatient = sessionPatientMemberDtos.find(
      i => i.patientId === speakerData.speakerUUID
    );
    if (speakerPatient) {
      speakerName = `${speakerPatient.patientFirstName || ""}${
        speakerPatient.patientLastName ? " " : ""
      }${speakerPatient.patientLastName || ""}`;
    }
    return speakerName;
  };
  if (speakerRecognitionData && Object.keys(speakerRecognitionData)?.length > 0) {
    Object.keys(speakerRecognitionData).map(speakerLabel => {
      let speakerName = getSpeakerName(speakerLabel);
      themesData = themesData.replaceAll(speakerLabel, speakerName);
      conflictsData = conflictsData.replaceAll(speakerLabel, speakerName);
    });
  }
  return (
    <div className="themes-conflicts-container">
      <div className="themes-conflicts-widget">
        <div className="widget-header">
          <div className="widget-title">Session Themes & Topics</div>
        </div>
        <div className="widget-content">
          <RichTextEditor
            value={themesData}
            readOnly={true}
          />
        </div>
      </div>
      <div className="themes-conflicts-widget">
        <div className="widget-header">
          <div className="widget-title">Group Conflicts or Challenges</div>
        </div>
        <div className="widget-content">
          <RichTextEditor
            value={conflictsData}
            readOnly={true}
          />
        </div>
      </div>
      <GroupDynamics
        sessionId={props.sessionId}
        sessionData={props.sessionData}
        speakerRecognition={props.speakerRecognition}
        {...props.soapNotes}
      />
    </div>
  );
}

export default ThemesConflicts;
