import React from "react";
import SessionLevels from "./components/SessionLevels";
import MemberDetails from "./components/MemberDetails";
import { RichTextEditor } from "@/components/RichTextEditor";
import { getLocalStorage } from "@/services/utils";
import { marked } from "marked";
import "./GroupDynamics.scss";

let storedGroupNotesData = getLocalStorage("groupNotesData");

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

function GroupDynamics(props) {
  // const groupNotesData = storedGroupNotesData?.[props.sessionId] || {};
  const groupNotesData = props.soapNotesJson?.groupExtendedNotes || {};
  let groupDynamicsData = groupNotesData?.groupDynamics
    ? convertMarkdownToHtml(groupNotesData.groupDynamics) || groupNotesData.groupDynamics
    : "";
  let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
    ? props.speakerRecognition?.data?.[props.sessionId][
        Object.keys(props.speakerRecognition.data[props.sessionId])[0]
      ]
    : null;
  const getSpeakerName = speakerLabel => {
    let speakerName = speakerLabel;
    let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
      ? props.speakerRecognition?.data?.[props.sessionId][
          Object.keys(props.speakerRecognition.data[props.sessionId])[0]
        ]
      : null;
    if (!speakerRecognitionData) {
      return speakerName;
    }
    let speakerData = speakerRecognitionData[speakerLabel] || null;
    if (!speakerData) {
      return speakerName;
    }
    if (speakerData.predictedLabel?.toLowerCase() === "provider") {
      if (props.sessionData?.practitionerFirstName) {
        speakerName = `${props.sessionData.practitionerFirstName || ""}${
          props.sessionData.practitionerLastName ? " " : ""
        }${props.sessionData.practitionerLastName || ""}`;
        return speakerName;
      }
    }
    let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
    let speakerPatient = sessionPatientMemberDtos.find(
      i => i.patientId === speakerData.speakerUUID
    );
    if (speakerPatient) {
      speakerName = `${speakerPatient.patientFirstName || ""}${
        speakerPatient.patientLastName ? " " : ""
      }${speakerPatient.patientLastName || ""}`;
    }
    return speakerName;
  };
  if (speakerRecognitionData && Object.keys(speakerRecognitionData)?.length > 0) {
    Object.keys(speakerRecognitionData).map(speakerLabel => {
      let speakerName = getSpeakerName(speakerLabel);
      groupDynamicsData = groupDynamicsData.replaceAll(speakerLabel, speakerName);
    });
  }
  return (
    <div className="group-dynamics-container">
      {APP_ENV === "DEV" && <div className="demo-deta-disclaimer">*Demo data</div>}
      <div className="group-dynamics-content">
        {APP_ENV === "DEV" && (
          <>
            <SessionLevels />
            <MemberDetails />
          </>
        )}
        <div className="group-dynamics-widget">
          <div className="widget-header">
            <div className="widget-title">Group Dynamics</div>
          </div>
          <div className="widget-content">
            <RichTextEditor
              value={groupDynamicsData}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupDynamics;
