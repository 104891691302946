import React, { useEffect, useState } from "react";
import "./GroupNotes.scss";
import { Avatar, RichTextEditor, SecondaryButton, TertiaryButton } from "@/components";
import "../../../GroupMemberInsights/components/MemberInsights/MemberInsights.scss";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import TreatmentPlan from "@/components/TreatmentPlanV2";

function GroupNotes(props) {
  const [selectedMember, setSelectedMember] = useState(null);
  const [showTreatmentPlanModal, setShowTreatmentPlanModal] = useState(false);
  const data = props.data?.groupNotes || {};
  useEffect(() => {
    if (data && (Object.keys(data).length > 0) & !selectedMember) {
      setSelectedMember(Object.keys(data)[0]);
    }
  }, [data]);
  const getSpeakerName = speakerLabel => {
    let speakerName = speakerLabel;
    let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
      ? props.speakerRecognition?.data?.[props.sessionId][
          Object.keys(props.speakerRecognition.data[props.sessionId])[0]
        ]
      : null;
    if (!speakerRecognitionData) {
      return speakerName;
    }
    let speakerData = speakerRecognitionData[speakerLabel] || null;
    if (!speakerData) {
      return speakerName;
    }
    if (speakerData.predictedLabel?.toLowerCase() === "provider") {
      if (props.sessionData?.practitionerFirstName) {
        speakerName = `${props.sessionData.practitionerFirstName || ""}${
          props.sessionData.practitionerLastName ? " " : ""
        }${props.sessionData.practitionerLastName || ""}`;
        return speakerName;
      }
    }
    let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
    let speakerPatient = sessionPatientMemberDtos.find(
      i => i.patientId === speakerData.speakerUUID
    );
    if (speakerPatient) {
      speakerName = `${speakerPatient.patientFirstName || ""}${
        speakerPatient.patientLastName ? " " : ""
      }${speakerPatient.patientLastName || ""}`;
    }
    return speakerName;
  };
  const getPatientDetails = speakerLabel => {
    let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
      ? props.speakerRecognition?.data?.[props.sessionId][
          Object.keys(props.speakerRecognition.data[props.sessionId])[0]
        ]
      : null;
    if (!speakerRecognitionData) {
      return null;
    }
    let speakerData = speakerRecognitionData[speakerLabel] || null;
    if (!speakerData) {
      return null;
    }
    if (speakerData.predictedLabel?.toLowerCase() === "provider") {
      return null;
    }
    let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
    let speakerPatient = sessionPatientMemberDtos.find(
      i => i.patientId === speakerData.speakerUUID
    );
    return speakerPatient || null;
  };
  const onChangeNotes = (patient, value) => {
    const prevData = {
      ...props.data,
      groupNotes: {
        ...(props.data.groupNotes || {}),
        [patient]: value,
      },
    };
    props.changeSoapJsonData(prevData);
  };
  const speakerName = getSpeakerName(selectedMember);
  const patientDetails = getPatientDetails(selectedMember);
  return (
    <div className="member-insights-widget group-notes-widget">
      <div className="member-insights-label">Patient Notes</div>
      <div className="group-notes-individual-notes-container">
        <div className="member-list">
          {Object.keys(data).map((member, index) => {
            return (
              <div
                className={`member-item ${selectedMember === member ? "selected" : ""}`}
                key={member}
                onClick={() => setSelectedMember(member)}
              >
                <div className="avatar-container">
                  <Avatar name={getSpeakerName(member)} />
                  <span className="avatar-name">{getSpeakerName(member)}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="insights-list">
          {selectedMember && (
            <div className="insights-item">
              <div className="member-top-container">
                <div className="patient-details">
                  <div className="patient-details-item">
                    <div className="patient-details-label">Patient Name:</div>
                    <div className="patient-details-value">{speakerName}</div>
                    {patientDetails && (
                      <Link
                        to={`/patients/${patientDetails.patientId}`}
                        target="_blank"
                        className="view-patient-profile-link"
                      ></Link>
                    )}
                  </div>
                  {patientDetails && (
                    <div className="patient-details-item">
                      <div className="patient-details-label">Phone Number:</div>
                      <div className="patient-details-value">
                        {patientDetails.patientPhoneNumber || "-"}
                      </div>
                    </div>
                  )}
                </div>
                {patientDetails && (
                  <>
                    <TertiaryButton onClick={() => setShowTreatmentPlanModal(true)}>
                      Show Treatment Plan
                    </TertiaryButton>
                    <TreatmentPlanModal
                      modalOpen={showTreatmentPlanModal}
                      toggleModal={setShowTreatmentPlanModal}
                      patientId={patientDetails.patientId}
                    />
                  </>
                )}
              </div>
              <div className="insight-desc">
                <textarea
                  className="individual-notes"
                  value={data[selectedMember]}
                  onChange={e => onChangeNotes(selectedMember, e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupNotes;

const TreatmentPlanModal = props => {
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="group-treatment-plan-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Treatment Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TreatmentPlan
          patientId={props.patientId}
          noAction={true}
        />
      </Modal.Body>
    </Modal>
  );
};
