import { get, post, rawGet, rawHead } from "@/services/api";
import { addLocalStorage, checkTodaySession, getLocalStorage } from "@/services/utils";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { marked } from "marked";

const SESSION_TRANSCRIPT_LLM_API_URL = import.meta.env.VITE_SESSION_TRANSCRIPT_LLM_API_URL;
const WEBSOCKET_API_URL = import.meta.env.VITE_IN_SESSION_SOCKET_URL;
const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const toTitleCase = str => {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const getSessionDetailsTranscript = options => {
  return async dispatch => {
    let transcriptData = [];
    if (!options?.noAction) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER",
        show: true,
        sessionId: options?.sessionId,
      });
    }
    try {
      const providerSessionsList = await getProviderSessions(options);
      let providerSessionTranscriptPromiseList = [];
      providerSessionsList.forEach(providerSession => {
        const startedAt = new Date(providerSession?.startedAt);
        const currentTime = new Date();
        const startTimePlus60 = new Date(
          startedAt.getTime() + (options?.sessionCategory === "individual" ? 2 : 4) * 60 * 60 * 1000
        ); //retry active till 2 hrs after starting the individual session & 4 hrs after starting the group session
        const retryTranscript = options?.noAction ? false : currentTime < startTimePlus60;
        let providerSessionTimer = null;
        let providerSessionTranscriptPromise = getProviderSessionTranscript({
          ...options,
          retryTranscript,
          session: providerSession,
          providerSessionTimer,
        });
        providerSessionTranscriptPromiseList.push(providerSessionTranscriptPromise);
      });
      try {
        let providerSessionTranscriptPromiseResponses = await Promise.allSettled(
          providerSessionTranscriptPromiseList
        );
        if (providerSessionTranscriptPromiseResponses?.length > 0) {
          providerSessionTranscriptPromiseResponses.forEach(providerSessionTranscriptResponse => {
            if (providerSessionTranscriptResponse?.value?.transcriptData?.length > 0) {
              let providerTranscriptData =
                providerSessionTranscriptResponse.value.transcriptData || [];
              let providerSessionId = providerSessionTranscriptResponse.value.providerSessionId;
              let providerSession = providerSessionTranscriptResponse.value.providerSession;
              transcriptData.push({
                providerSessionId,
                providerSession,
                transcriptData: providerTranscriptData,
              });
            }
          });
        }
      } catch (error) {
        console.error("Error fetching provider sessions transcripts:", error);
      }
    } catch (error) {
      console.error("Error fetching provider sessions:", error);
    }
    if (!options?.noAction) {
      dispatch({
        type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
        data: transcriptData,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } else {
      return transcriptData;
    }
  };
};

const getProviderSessions = async options => {
  let url =
    options?.sessionCategory === "individual"
      ? `practitioner-role/meeting-session/individual-session/${options?.sessionId}/list/reconciled-provider-sessions`
      : `practitioner-role/meeting-session/group/${options?.sessionId}/list/reconciled-provider-sessions`;
  try {
    let response = await get(url);
    let providerSessionsList = response?.data || [];
    providerSessionsList = providerSessionsList.sort(
      (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
    );
    return providerSessionsList;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSessionSpeakerProfilesEnrollmentStatus = options => {
  return async dispatch => {
    let speakers = options.speakers || [];
    let sessionId = options?.sessionId;
    let sessionData = options.sessionData;
    let initSpeakerProfileEnrollmentStatusCheckPromises = [];
    let allSpeakersEnrolled = false;
    speakers.forEach(speaker => {
      initSpeakerProfileEnrollmentStatusCheckPromises.push(
        dispatch(getSpeakerProfileEnrollmentStatus(sessionId, speaker, true))
      );
    });
    try {
      let responses = await Promise.allSettled(initSpeakerProfileEnrollmentStatusCheckPromises);
      let speakerProfileEnrollmentStatusList = responses
        .map(response => {
          return response.value || null;
        })
        .filter(i => !!i);
      if (speakers.length === speakerProfileEnrollmentStatusList.length) {
        allSpeakersEnrolled = true;
      }
    } catch (error) {
      console.log("error", error);
    }
    if (
      !allSpeakersEnrolled &&
      options?.sessionCategory === "individual" &&
      checkTodaySession(sessionData)
    ) {
      dispatch({
        type: "TOGGLE_SPEAKER_PROFILE_ENROLLMENT_STATUS_LOADER",
        show: true,
        sessionId: options?.sessionId,
      });
      let speakerProfileEnrollmentStatusCheckPromises = [];
      speakers.forEach(speaker => {
        speakerProfileEnrollmentStatusCheckPromises.push(
          dispatch(getSpeakerProfileEnrollmentStatus(sessionId, speaker))
        );
      });
      try {
        await Promise.allSettled(speakerProfileEnrollmentStatusCheckPromises);
      } catch (error) {
        console.log("error", error);
      }
      dispatch({
        type: "TOGGLE_SPEAKER_PROFILE_ENROLLMENT_STATUS_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    }
  };
};

export const getSpeakerProfileEnrollmentStatus = (
  sessionId,
  { isPatient, patientId, practitionerRoleId },
  noRetry = false,
  prevAttempt = 0
) => {
  return async dispatch => {
    const maxRetries = 30;
    let attempt = prevAttempt;
    if (noRetry) {
      attempt = maxRetries;
    }
    const retryStatusCheck = async () => {
      if (attempt >= maxRetries) {
        console.error("Max retry attempts reached for", {
          isPatient,
          patientId,
          practitionerRoleId,
        });
        return null;
      }
      attempt++;
      console.log(`Retrying (${attempt}/${maxRetries})...`);
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(
            dispatch(
              getSpeakerProfileEnrollmentStatus(
                sessionId,
                { isPatient, patientId, practitionerRoleId },
                false,
                attempt
              )
            )
          );
        }, 10000); // Retry after 10 seconds
      });
    };
    let url = isPatient
      ? `practitioner-role/patient/speaker-profile-enrollment/find-by-id/${patientId}`
      : `practitioner-role/practitioner-role/speaker-profile-enrollment/find-by-id/${practitionerRoleId}`;
    try {
      let response = await get(url);
      let speakerProfileEnrollmentFileUrl = response?.data || null;
      if (speakerProfileEnrollmentFileUrl) {
        try {
          let speakerProfileEnrollmentFileContent = await fetch(speakerProfileEnrollmentFileUrl, {
            headers: {
              Range: "bytes=0-1000",
            },
          });
          let speakerProfileEnrollmentStatus = null;
          if (speakerProfileEnrollmentFileContent) {
            const audioDuration = await getAudioDuration(speakerProfileEnrollmentFileContent);
            speakerProfileEnrollmentStatus = {
              speakerType: isPatient ? "Patient" : "Provider",
              patientId,
              practitionerRoleId,
              speakerProfileEnrollmentFileUrl,
              speakerProfileEnrolled: true,
              audioDuration,
            };
            dispatch({
              type: "ADD_SPEAKER_PROFILE_ENROLLMENT",
              data: speakerProfileEnrollmentStatus,
              sessionId: sessionId,
            });
            return speakerProfileEnrollmentStatus;
          } else {
            if (noRetry) {
              return null;
            }
            return await retryStatusCheck();
          }
        } catch (error) {
          if (noRetry) {
            return null;
          }
          return await retryStatusCheck();
          // console.log("error", error);
        }
      } else {
        if (noRetry) {
          return null;
        }
        return await retryStatusCheck();
      }
    } catch (error) {
      if (noRetry) {
        return null;
      }
      return await retryStatusCheck();
    }
  };
};

export const getSessionSpeakerRecognitionStatus = options => {
  return async dispatch => {
    const { sessionId, providerSessionsSpeakers, sessionData } = options;
    let totalSpeakers = 0;
    let initSpeakerRecognitionStatusCheckPromises = [];
    let allSpeakersRecognised = false;
    providerSessionsSpeakers.forEach(providerSession => {
      let providerSessionId = providerSession.providerSessionId;
      providerSession.speakers.forEach(speakerId => {
        initSpeakerRecognitionStatusCheckPromises.push(
          dispatch(
            getSpeakerRecognitionStatus(
              { sessionId, providerSessionId, speakerId },
              true,
              0,
              options?.noAction
            )
          )
        );
      });
    });
    try {
      let responses = await Promise.allSettled(initSpeakerRecognitionStatusCheckPromises);
      let speakerRecognitionStatusList = responses
        .map(response => {
          return response.value || null;
        })
        .filter(i => !!i);
      if (totalSpeakers.length === speakerRecognitionStatusList.length) {
        allSpeakersRecognised = true;
      }
      if (options?.noAction) {
        return speakerRecognitionStatusList;
      }
    } catch (error) {
      console.log("error", error);
      if (options?.noAction) {
        return [];
      }
    }
    if (!allSpeakersRecognised && checkTodaySession(sessionData) && !options?.noAction) {
      dispatch({
        type: "TOGGLE_SPEAKER_RECOGNITION_STATUS_LOADER",
        show: true,
        sessionId: options?.sessionId,
      });
      let speakerRecognitionStatusCheckPromises = [];
      providerSessionsSpeakers.forEach(providerSession => {
        let providerSessionId = providerSession.providerSessionId;
        providerSession.speakers.forEach(speakerId => {
          speakerRecognitionStatusCheckPromises.push(
            dispatch(getSpeakerRecognitionStatus({ sessionId, providerSessionId, speakerId }))
          );
        });
      });
      try {
        await Promise.allSettled(speakerRecognitionStatusCheckPromises);
      } catch (error) {
        console.log("error", error);
      }
      dispatch({
        type: "TOGGLE_SPEAKER_RECOGNITION_STATUS_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    }
  };
};

export const getSpeakerRecognitionStatus = (
  { sessionId, providerSessionId, speakerId },
  noRetry = false,
  prevAttempt = 0,
  noAction
) => {
  return async dispatch => {
    const maxRetries = 30;
    let attempt = prevAttempt;
    if (noRetry) {
      attempt = maxRetries;
    }
    const retryStatusCheck = async () => {
      if (attempt >= maxRetries) {
        console.error("Max retry attempts reached for", {
          sessionId,
          providerSessionId,
          speakerId,
        });
        return null;
      }
      attempt++;
      console.log(`Retrying (${attempt}/${maxRetries})...`);
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(
            dispatch(
              getSpeakerRecognitionStatus(
                { sessionId, providerSessionId, speakerId },
                false,
                attempt
              )
            )
          );
        }, 10000); // Retry after 10 seconds
      });
    };
    let url = `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/speaker-recognition-results-from-audio/speaker/${speakerId}`;
    try {
      let response = await get(url);
      let speakerRecognitionFileUrl = response?.data || null;
      if (speakerRecognitionFileUrl) {
        try {
          let speakerRecognitionFileContent = await rawGet(speakerRecognitionFileUrl);
          if (speakerRecognitionFileContent?.data) {
            let predictionData = getMostAccuratePrediction(speakerRecognitionFileContent.data);
            let speakerRecognitionData = {
              speakerId: `Speaker ${speakerId}`,
              sessionId,
              providerSessionId,
              data: getMostAccuratePrediction(speakerRecognitionFileContent.data),
            };
            if (!noAction) {
              dispatch({
                type: "ADD_SPEAKER_RECOGNITION_DATA",
                ...speakerRecognitionData,
                sessionId: sessionId,
              });
            }
            return speakerRecognitionData;
          } else {
            if (noRetry) {
              return null;
            }
            return await retryStatusCheck();
          }
        } catch (error) {
          if (noRetry) {
            return null;
          }
          return await retryStatusCheck();
          // console.log("error", error);
        }
      } else {
        if (noRetry) {
          return null;
        }
        return await retryStatusCheck();
      }
    } catch (error) {
      if (noRetry) {
        return null;
      }
      return await retryStatusCheck();
    }
  };
};

const getMostAccuratePrediction = data => {
  if (!Array.isArray(data) || data.length === 0) {
    return { predictedLabel: null, speakerUUID: null };
  }

  // Sort the data by score in descending order
  const sortedData = data.filter(i => i.score > 0.5).sort((a, b) => b.score - a.score);

  // Find the first entry with a predictedLabel and speakerUUID
  for (const entry of sortedData) {
    const predictedLabelPayload = entry.payload.find(p => p.key === "predictedLabel");
    const speakerUUIDPayload = entry.payload.find(p => p.key === "speakerUUID");

    if (predictedLabelPayload && speakerUUIDPayload) {
      return {
        predictedLabel: predictedLabelPayload?.value?.stringValue || null,
        speakerUUID: speakerUUIDPayload?.value?.stringValue || null,
      };
    }
  }

  // If no valid entry found
  return { predictedLabel: null, speakerUUID: null };
};

export const getSessionDetails = options => {
  return async dispatch => {
    let url = `practitioner-role/sessions/find-by-id/${options?.sessionId}`;
    if (options?.sessionCategory === "individual") {
      url = `practitioner-role/individual-session/find-by-id/${options?.sessionId}`;
    }
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_SESSION_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    let sessionDetails = null;
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data || null;
        sessionDetails = data;
        dispatch({ type: "ADD_SESSION_DETAILS_SESSION_DATA", data, sessionId: options?.sessionId });
      }
    } catch (error) {}
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_SESSION_LOADER",
      show: false,
      sessionId: options?.sessionId,
    });
    return sessionDetails;
  };
};

export const getMemberNotes = options => {
  return async dispatch => {
    let url = `practitioner-role/member-notes/${options?.sessionId}/find`;
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_MEMBER_NOTES_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    let memberNotes = {};
    try {
      let response = await get(url);
      if (response?.data) {
        let memberNotesList = [...response.data];
        memberNotes = memberNotesList.find(i => i.userId === options?.patientId) || {};
      }
    } catch (error) {}
    dispatch({
      type: "ADD_SESSION_DETAILS_MEMBER_NOTES",
      data: memberNotes,
      sessionId: options?.sessionId,
    });
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_MEMBER_NOTES_LOADER",
      show: false,
      sessionId: options?.sessionId,
    });
  };
};

export const updateMemberNotes = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let payload = {
      ...options.payload,
      practitionerRoleId,
    };
    let url = "practitioner-role/member-notes/update";
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_UPDATE_NOTES_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    try {
      let response = await post(url, payload);
      dispatch({
        type: "UPDATE_SESSION_DETAILS_MEMBER_NOTES",
        data: payload,
        sessionId: options?.sessionId,
      });
      if (options?.successCb) {
        options.successCb();
      }
    } catch (error) {
      if (options?.errorCb) {
        options.errorCb();
      }
    }
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_UPDATE_NOTES_LOADER",
      show: false,
      sessionId: options?.sessionId,
    });
  };
};

export const getProviderSessionIds = options => {
  return async dispatch => {
    let transcriptData = [];
    let csvData = [];
    let providerSessionTranscriptsData = [];
    let speakerMapped = true;
    let url =
      options?.sessionCategory === "individual"
        ? `practitioner-role/meeting-session/individual-session/${options?.sessionId}/list/reconciled-provider-sessions`
        : `practitioner-role/meeting-session/group/${options?.sessionId}/list/reconciled-provider-sessions`;
    if (!options?.fromTasks) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER",
        show: true,
        sessionId: options?.sessionId,
      });
    }
    try {
      let response = await get(url);
      let sessionProvidersList = response?.data || [];
      sessionProvidersList = sessionProvidersList.sort(
        (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
      );
      let retryTranscript = false;
      if (!!sessionProvidersList?.[sessionProvidersList.length - 1]) {
        let latestProviderSessionDetails = sessionProvidersList[sessionProvidersList.length - 1];
        const startedAt = new Date(latestProviderSessionDetails?.startedAt);
        const currentTime = new Date();
        const startTimePlus60 = new Date(startedAt.getTime() + 2 * 60 * 60000);
        retryTranscript = currentTime < startTimePlus60;
      }
      if (!!options?.fromTasks) {
        retryTranscript = false;
      }
      let promises = [];
      sessionProvidersList.forEach(sessionProvider => {
        let providerSessionTimer = null;
        let promise = getProviderSessionTranscript({
          ...options,
          retryTranscript,
          session: sessionProvider,
          providerSessionTimer,
        });
        promises.push(promise);
      });
      let responses = await Promise.allSettled(promises);
      if (responses?.length > 0) {
        responses.forEach(response => {
          if (response?.value) {
            let providerTranscriptData = response.value.transcriptData || [];
            let providerCsvData = response.value.csvData || [];
            let providerSpeakerMapped = !!response.value.speakerMapped;
            transcriptData = [...transcriptData, ...providerTranscriptData];
            csvData = [...csvData, ...providerCsvData];
            // if (options?.sessionCategory === "individual") {
            //   let actualSpeakers = [...new Set(providerTranscriptData.map(i => i.memberId))];
            //   let mappedSpeakers = [...new Set(providerTranscriptData.map(i => i.memberName))];
            //   console.log("actualSpeakers", actualSpeakers);
            //   console.log("mappedSpeakers", mappedSpeakers);

            //   if (actualSpeakers.length === mappedSpeakers.length) {
            //     providerSpeakerMapped = true;
            //   } else {
            //     providerSpeakerMapped = false;
            //   }
            // }
            speakerMapped = speakerMapped && providerSpeakerMapped;
            providerSessionTranscriptsData.push({
              providerSessionId: response.value.providerSessionId,
              csvData: providerCsvData,
              transcriptData: providerTranscriptData,
              speakerMapped: providerSpeakerMapped,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    if (!options?.fromTasks) {
      dispatch({
        type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
        data: transcriptData,
        csvData,
        providerSessionTranscriptsData,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_TRANSCRIPT_SPEAKER_MAPPING_STATUS",
        flag: !!speakerMapped,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } else {
      return transcriptData;
    }
  };
};

export const getProviderSessionTranscript = async options => {
  let { session, sessionCategory, triggerTime, providerSessionTimer, retryTranscript } = options;
  let secondsPassed = (new Date() - triggerTime) / 1000;
  const timeoutTime = APP_ENV === "PROD" ? 600 : 120; // retry for 10 minutes on PROD and 2 minuted on DEV
  if (secondsPassed >= timeoutTime) {
    retryTranscript = false;
  }
  if (providerSessionTimer) {
    clearTimeout(providerSessionTimer);
  }
  let providerSessionTranscriptData = null;
  let sessionId =
    sessionCategory === "individual" ? session?.individualSessionId : session?.sessionId;
  let providerSessionId = session?.providerSessionId;
  // let url =
  //   sessionCategory === "individual"
  //     ? `practitioner-role/meeting-session/individual-session/${sessionId}/provider-session-id/${providerSessionId}/transcript`
  //     : `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/transcript`;
  let url =
    sessionCategory === "individual"
      ? `practitioner-role/meeting-session/individual-session/${sessionId}/provider-session-id/${providerSessionId}/transcript-from-audio`
      : `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/transcript-from-audio`;
  if (session.modeOfDelivery === "IN_PERSON") {
    url =
      sessionCategory === "individual"
        ? `practitioner-role/meeting-session/individual-session/${sessionId}/provider-session-id/${providerSessionId}/transcript-from-audio`
        : `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/transcript-from-audio`;
  }
  try {
    let response = await get(url);
    if (response?.data) {
      let transcriptUrl = response.data;
      let pdfPromise = new Promise((resolve, reject) => {
        let resultData = null;
        Papa.parse(transcriptUrl, {
          download: true,
          complete: function (results) {
            let data = results?.data || [];
            let transcriptData = data
              .map(i => {
                return {
                  timestamp: Number(i[0]),
                  providerParticipantId: i[1],
                  providerPeerId: i[2],
                  memberId: i[3],
                  memberName: i[4],
                  transcriptText: i[5],
                };
              })
              .sort((a, b) => a.timestamp - b.timestamp);
            resultData = {
              transcriptData,
              csvData: data,
              providerSessionId,
              providerSession: session,
              sessionId,
            };
            if (transcriptData?.length > 0) {
              resolve(resultData);
            } else {
              if (!!retryTranscript) {
                providerSessionTimer = setTimeout(async () => {
                  let resultRecurData = await getProviderSessionTranscript(options);
                  resolve(resultRecurData);
                }, 10000);
              } else {
                reject(resultData);
              }
            }
          },
          error: function () {
            if (!!retryTranscript) {
              providerSessionTimer = setTimeout(async () => {
                let resultRecurData = await getProviderSessionTranscript(options);
                resolve(resultRecurData);
              }, 10000);
            } else {
              reject(resultData);
            }
          },
        });
      });
      try {
        providerSessionTranscriptData = await pdfPromise;
      } catch (error) {
        console.log(error, providerSessionId);
      }
    } else {
      if (!!retryTranscript) {
        providerSessionTimer = setTimeout(async () => {
          let resultRecurData = await getProviderSessionTranscript(options);
          providerSessionTranscriptData = resultRecurData;
        }, 10000);
      } else {
        providerSessionTranscriptData = null;
      }
    }
  } catch (error) {
    console.log("error", error, providerSessionId);
    if (!!retryTranscript) {
      providerSessionTimer = setTimeout(async () => {
        let resultRecurData = await getProviderSessionTranscript(options);
        providerSessionTranscriptData = resultRecurData;
      }, 10000);
    } else {
      providerSessionTranscriptData = null;
    }
  }
  return providerSessionTranscriptData;
};

export const getSessionMemberNotes = options => {
  return async dispatch => {
    let { sessionId, patientId, groupId } = options;
    let url = `practitioner-role/session-member-notes/session/${sessionId}/find`;
    if (!options.noAction) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
        show: true,
        sessionId: options?.sessionId,
      });
    }
    let soapNotesData = "";
    let bhPredictionsData = null;
    let notesId = "";
    let soapNotesJson = null;
    let soapNotesJsonData = null;
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        notesId = response?.data?.find(i => i.patientId === patientId || i.groupId === groupId)?.id;
        soapNotesJson = response?.data?.find(
          i => i.patientId === patientId || i.groupId === groupId
        )?.jsonSoapNote;
        if (soapNotesJson) {
          if (
            !soapNotesJson.hasOwnProperty("groupExtendedNotes") &&
            !soapNotesJson.hasOwnProperty("groupNotes")
          ) {
            soapNotesJsonData = {};
            Object.keys(soapNotesJson)?.forEach(section => {
              soapNotesJsonData[
                section !== "chiefCompliantEnhanced" ? section.toLowerCase() : section
              ] = soapNotesJson[section];
            });
            let newData = {};
            Object.keys(soapNotesJsonData)?.forEach(section => {
              if (
                section !== "narrative" &&
                section !== "intake-assessment" &&
                section !== "chiefCompliantEnhanced"
              ) {
                let subSections = Object.keys(soapNotesJsonData[section]);
                subSections?.forEach(subSection => {
                  let subSectionObj = soapNotesJsonData[section][subSection];
                  if (subSectionObj?.type === "single_choice_answer") {
                    let subSectionResult = subSectionObj.result;
                    let valuesIdentified =
                      subSectionObj?.values_identified?.length > 0
                        ? [...subSectionObj.values_identified]
                        : [];
                    let valuesRemaining =
                      subSectionObj?.values_remaining?.length > 0
                        ? [...subSectionObj.values_remaining]
                        : [];
                    if (
                      valuesIdentified?.includes(subSectionResult) ||
                      valuesRemaining?.includes(subSectionResult)
                    ) {
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: subSectionObj,
                            }
                          : {
                              [subSection]: subSectionObj,
                            },
                      };
                    } else {
                      valuesIdentified = [subSectionResult];
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: {
                                ...subSectionObj,
                                values_identified: valuesIdentified,
                              },
                            }
                          : {
                              [subSection]: {
                                ...subSectionObj,
                                values_identified: valuesIdentified,
                              },
                            },
                      };
                    }
                  } else if (subSectionObj?.type === "multiple_choice_answers") {
                    let subSectionResult =
                      subSectionObj?.result?.length > 0 ? [...subSectionObj.result] : [];
                    let valuesIdentified =
                      subSectionObj?.values_identified?.length > 0
                        ? [...subSectionObj.values_identified]
                        : [];
                    let valuesRemaining =
                      subSectionObj?.values_remaining?.length > 0
                        ? [...subSectionObj.values_remaining]
                        : [];
                    if (subSectionResult?.length > 0) {
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: subSectionObj,
                            }
                          : {
                              [subSection]: subSectionObj,
                            },
                      };
                    } else {
                      subSectionResult = valuesIdentified;
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: {
                                ...subSectionObj,
                                result: subSectionResult,
                              },
                            }
                          : {
                              [subSection]: {
                                ...subSectionObj,
                                result: subSectionResult,
                              },
                            },
                      };
                    }
                  } else {
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: subSectionObj,
                          }
                        : {
                            [subSection]: subSectionObj,
                          },
                    };
                  }
                });
              } else {
                if (section === "narrative") {
                  newData = {
                    ...newData,
                    narrative: soapNotesJsonData.narrative,
                  };
                } else if (section === "intake-assessment") {
                  newData = {
                    ...newData,
                    ["intake-assessment"]: soapNotesJsonData["intake-assessment"],
                  };
                } else if (section === "chiefCompliantEnhanced") {
                  newData = {
                    ...newData,
                    ["chiefCompliantEnhanced"]: soapNotesJsonData["chiefCompliantEnhanced"],
                  };
                }
              }
            });
            soapNotesJsonData = newData;
          } else {
            soapNotesJsonData = soapNotesJson;
          }
        }
        soapNotesData = response?.data?.find(
          i => i.patientId === patientId || i.groupId === groupId
        )?.soapNote;
        bhPredictionsData = response?.data?.find(
          i => i.patientId === patientId || i.groupId === groupId
        )?.behaviouralHealthPredictions;
      }
    } catch (error) {}
    if (!options.noAction) {
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
        data: soapNotesData,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
        data: soapNotesJsonData,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_ID",
        notesId,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA",
        data: bhPredictionsData,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_ID",
        notesId,
        sessionId: options?.sessionId,
      });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    }
    return { soapNotesData, soapNotesJsonData, notesId, bhPredictionsData };
  };
};

// export const generateSoapNotes = options => {
//   return async dispatch => {
//     let { sessionId, userId } = options;
//     let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note`;
//     if (options?.sessionCategory === "group") {
//       url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/group/extended`;
//     }
//     let payload = options?.payload || {};
//     dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: true });
//     if (!options?.regenrate && options.sessionCategory !== "group") {
//       dispatch(generateBhPredictions(options));
//     }
//     try {
//       let response = await post(url, payload);
//       let soapNotesData =
//         options?.sessionCategory === "group"
//           ? response?.data?.groupProgressNote || ""
//           : response?.data || "";
//       if (options?.sessionCategory === "group" && response?.data && options?.sessionId) {
//         let storedGroupNotesData = getLocalStorage("groupNotesData") || {};
//         storedGroupNotesData = {
//           ...storedGroupNotesData,
//           [options.sessionId]: response.data,
//         };
//         addLocalStorage("groupNotesData", storedGroupNotesData);
//       }
//       // let soapNotesData = response?.data || "";
//       if (soapNotesData) {
//         dispatch({
//           type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
//           data: soapNotesData,
//         });
//       }
//       await dispatch(saveSoapNotes({ ...options, soapNote: soapNotesData }));
//       dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
//     } catch (error) {
//       dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
//       if (!payload.transcript) {
//         toast.error("No transcripts found for this user", { position: "bottom-left" });
//       } else {
//         toast.error("Error generating Notes, please try again", { position: "bottom-left" });
//       }
//     }
//   };
// };

export const generateSoapNotes = options => {
  return async dispatch => {
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note`;
    if (options?.sessionCategory === "group") {
      if (options?.generateGroupExtended) {
        url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/group/extended`;
      } else {
        url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/group/v2/from-csv-rows`;
      }
    }
    let notesTemplate = options?.notesTemplate; //default, assessment;
    if (options?.sessionCategory === "individual" && notesTemplate === "assessment") {
      url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/custom/serenity`;
    }
    if (options?.sessionCategory === "individual" && notesTemplate === "intake") {
      url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-intake-note/custom/bps-template`;
      if (options?.hpiNote) {
        url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-intake-note/custom/hpi-note`;
      }
    }
    let payload = options?.payload || {};
    if (!options?.generateGroupExtended) {
      if (!options?.hideLoader) {
        dispatch({
          type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
          show: true,
          sessionId: options?.sessionId,
        });
      }
    }

    if (!options?.regenrate && options.sessionCategory !== "group") {
      //Calling soap notes generation and prediction generation api together is failing
      // dispatch(generateBhPredictions(options));
    }
    // if (!!options?.regenrate) {
    //   dispatch({
    //     type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
    //     data: null,
    //   });
    // }
    try {
      let response = await post(url, payload);
      let soapNotesData = options?.soapNote || "";
      let soapNotesJsonData = options?.soapNotesJson || null;
      if (options?.sessionCategory === "group") {
        if (options?.generateGroupExtended) {
          soapNotesData = response?.data?.groupProgressNote || "";
          if (response?.data) {
            soapNotesJsonData = soapNotesJsonData
              ? { ...soapNotesJsonData, ["groupExtendedNotes"]: response.data }
              : { ["groupExtendedNotes"]: response.data };
          }
        } else {
          soapNotesJsonData = soapNotesJsonData
            ? { ...soapNotesJsonData, ["groupNotes"]: response.data }
            : { ["groupNotes"]: response.data };
        }
      } else {
        if (response?.data) {
          if (typeof response.data === "string") {
            if (notesTemplate === "intake" && options?.hpiNote) {
              let noteData = { intakeHPINote: response.data.replaceAll("*", "") };
              soapNotesJsonData = soapNotesJsonData
                ? {
                    ...soapNotesJsonData,
                    ["intake-assessment"]: soapNotesJsonData["intake-assessment"]
                      ? {
                          ...soapNotesJsonData["intake-assessment"],
                          ...noteData,
                        }
                      : noteData,
                  }
                : { ["intake-assessment"]: noteData };
            } else {
              soapNotesData = response?.data || "";
            }
            // if (options?.sessionCategory === "individual" && notesTemplate === "intake") {
            //   soapNotesData = marked.parse(soapNotesData);
            // }
          } else {
            if (notesTemplate === "assessment") {
              soapNotesJsonData = soapNotesJsonData
                ? { ...soapNotesJsonData, ...response.data }
                : response.data;
            } else if (notesTemplate === "intake") {
              let noteData = options?.hpiNote ? { intakeHPINote: response.data } : response.data;
              soapNotesJsonData = soapNotesJsonData
                ? {
                    ...soapNotesJsonData,
                    ["intake-assessment"]: soapNotesJsonData["intake-assessment"]
                      ? {
                          ...soapNotesJsonData["intake-assessment"],
                          ...noteData,
                        }
                      : noteData,
                  }
                : { ["intake-assessment"]: noteData };
            }
          }
        }
      }
      console.log("soapNotesJsonData test", soapNotesJsonData);

      if (response?.data && options?.sessionId) {
        if (options?.sessionCategory === "group") {
          let storedGroupNotesData = getLocalStorage("groupNotesData") || {};
          storedGroupNotesData = {
            ...storedGroupNotesData,
            [options.sessionId]: response.data,
          };
          addLocalStorage("groupNotesData", storedGroupNotesData);
        } else {
          let storedSoapNotesData = getLocalStorage("soapNotesData") || {};
          storedSoapNotesData = {
            ...storedSoapNotesData,
            [options.sessionId]: response.data,
          };
          addLocalStorage("soapNotesData", storedSoapNotesData);
        }
      }
      if (soapNotesData) {
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
          data: soapNotesData,
          sessionId: options?.sessionId,
        });
      }
      let soapNotesJson = null;
      if (soapNotesJsonData) {
        if (
          !soapNotesJsonData.hasOwnProperty("groupExtendedNotes") &&
          !soapNotesJsonData.hasOwnProperty("groupNotes")
        ) {
          soapNotesJson = {};
          Object.keys(soapNotesJsonData)?.forEach(section => {
            soapNotesJson[section !== "chiefCompliantEnhanced" ? section.toLowerCase() : section] =
              soapNotesJsonData[section];
          });
          let newData = {};
          Object.keys(soapNotesJson)?.forEach(section => {
            if (
              section !== "narrative" &&
              section !== "intake-assessment" &&
              section !== "chiefCompliantEnhanced"
            ) {
              let subSections = Object.keys(soapNotesJson[section]);
              subSections?.forEach(subSection => {
                let subSectionObj = soapNotesJson[section][subSection];
                if (subSectionObj?.type === "single_choice_answer") {
                  let subSectionResult = subSectionObj.result;
                  let valuesIdentified =
                    subSectionObj?.values_identified?.length > 0
                      ? [...subSectionObj.values_identified]
                      : [];
                  let valuesRemaining =
                    subSectionObj?.values_remaining?.length > 0
                      ? [...subSectionObj.values_remaining]
                      : [];
                  if (
                    valuesIdentified?.includes(subSectionResult) ||
                    valuesRemaining?.includes(subSectionResult)
                  ) {
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: subSectionObj,
                          }
                        : {
                            [subSection]: subSectionObj,
                          },
                    };
                  } else {
                    valuesIdentified = [subSectionResult];
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: {
                              ...subSectionObj,
                              values_identified: valuesIdentified,
                            },
                          }
                        : {
                            [subSection]: {
                              ...subSectionObj,
                              values_identified: valuesIdentified,
                            },
                          },
                    };
                  }
                } else if (subSectionObj?.type === "multiple_choice_answers") {
                  let subSectionResult =
                    subSectionObj?.result?.length > 0 ? [...subSectionObj.result] : [];
                  let valuesIdentified =
                    subSectionObj?.values_identified?.length > 0
                      ? [...subSectionObj.values_identified]
                      : [];
                  let valuesRemaining =
                    subSectionObj?.values_remaining?.length > 0
                      ? [...subSectionObj.values_remaining]
                      : [];
                  if (subSectionResult?.length > 0) {
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: subSectionObj,
                          }
                        : {
                            [subSection]: subSectionObj,
                          },
                    };
                  } else {
                    subSectionResult = valuesIdentified;
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: {
                              ...subSectionObj,
                              result: subSectionResult,
                            },
                          }
                        : {
                            [subSection]: {
                              ...subSectionObj,
                              result: subSectionResult,
                            },
                          },
                    };
                  }
                } else {
                  newData = {
                    ...newData,
                    [section]: newData[section]
                      ? {
                          ...newData[section],
                          [subSection]: subSectionObj,
                        }
                      : {
                          [subSection]: subSectionObj,
                        },
                  };
                }
              });
            } else {
              if (section === "narrative") {
                newData = {
                  ...newData,
                  narrative: soapNotesJsonData.narrative,
                };
              } else if (section === "intake-assessment") {
                newData = {
                  ...newData,
                  ["intake-assessment"]: soapNotesJsonData["intake-assessment"],
                };
              } else if (section === "chiefCompliantEnhanced") {
                newData = {
                  ...newData,
                  ["chiefCompliantEnhanced"]: soapNotesJsonData["chiefCompliantEnhanced"],
                };
              }
            }
          });
          console.log("newData", newData);

          soapNotesJson = newData;
        } else {
          soapNotesJson = soapNotesJsonData;
        }
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
          data: soapNotesJson,
          sessionId: options?.sessionId,
        });
      }
      console.log("soapNotesJson 1", soapNotesJson);

      await dispatch(
        saveSoapNotes({ ...options, soapNote: soapNotesData, soapNotesJson: soapNotesJson })
      );
      if (!options?.generateGroupExtended) {
        if (!options?.hideLoader) {
          dispatch({
            type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
            show: false,
            sessionId: options?.sessionId,
          });
        }
      }
      if (options?.sessionCategory === "group" && !options?.generateGroupExtended) {
        await dispatch(
          generateSoapNotes({
            ...options,
            soapNote: soapNotesData,
            soapNotesJson: soapNotesJson,
            generateGroupExtended: true,
          })
        );
      }
      if (
        options?.sessionCategory === "individual" &&
        notesTemplate === "intake" &&
        options?.hpiNote
      ) {
        dispatch(
          generateSoapNotes({
            ...options,
            soapNote: soapNotesData,
            soapNotesJson: soapNotesJson,
            hpiNote: false,
            hideLoader: true,
          })
        );
      }
    } catch (error) {
      if (!options?.generateGroupExtended) {
        if (!options?.hideLoader) {
          dispatch({
            type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
            show: false,
            sessionId: options?.sessionId,
          });
        }
      }
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating Notes, please try again", { position: "bottom-left" });
      }
    }
  };
};

export const saveSoapNotes = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let { sessionId, groupId, userId, soapNote, patientId, soapNotesJson } = options;
    let url = "practitioner-role/session-member-notes/save-soap-notes";
    let practitionerRoleId = state.user.selectedUserRole?.id;
    if (!options?.hideLoader) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
        show: true,
        sessionId: options?.sessionId,
      });
    }
    const { soapNotesJsonData, notesId } = await dispatch(
      getSessionMemberNotes({ sessionId, patientId, groupId, noAction: true })
    );
    let jsonSoapNote = soapNotesJsonData ? { ...soapNotesJsonData } : {};
    console.log("jsonSoapNote", jsonSoapNote);
    console.log("soapNotesJson 2", soapNotesJson);
    if (soapNotesJson) {
      jsonSoapNote = {
        ...jsonSoapNote,
        ...soapNotesJson,
      };
    }
    console.log("soapNotesJson 3", soapNotesJson);
    let payload = {
      id: notesId,
      sessionId,
      groupId,
      userId,
      soapNote,
      jsonSoapNote,
      patientId,
      practitionerRoleId,
    };
    try {
      let response = await post(url, payload);
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
        data: soapNote || "",
        sessionId: options?.sessionId,
      });
      if (response?.id) {
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_ID",
          notesId: response?.id,
          sessionId: options?.sessionId,
        });
      }
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
        data: soapNotesJson || null,
        sessionId: options?.sessionId,
      });
      toast.success("Notes Saved", { position: "bottom-left" });
      if (!options?.hideLoader) {
        dispatch({
          type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
          show: false,
          sessionId: options?.sessionId,
        });
      }
      fetch(`${WEBSOCKET_API_URL}/send-notes-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          individualSessionId: sessionId,
          patientId: patientId,
          status: "created",
        }),
      })
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.error(error));
    } catch (error) {
      if (!options?.hideLoader) {
        dispatch({
          type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
          show: false,
          sessionId: options?.sessionId,
        });
      }
    }
    // if (options?.sessionCategory === "group") {
    // window.location.href = window.location.href;
    // }
  };
};

export const generateTreatmentPlan = options => {
  return async dispatch => {
    let { sessionId, patientId, session } = options;
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-revised-treatment-plan`;
    let payload = options?.payload || {};
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    try {
      let response = await post(url, payload);
      if (response?.data) {
        if (typeof response.data === "string") {
          throw new Error("Error generating treatment plan, please try again");
        }
        dispatch({
          type: "ADD_SESSION_DETAILS_TREATMENT_PLAN_DATA",
          data: response.data,
          sessionId: options?.sessionId,
        });
        // await dispatch(saveBhPredictions({ ...options, bhPredictions: response.data }));
      }
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating treatment plan, please try again", {
          position: "bottom-left",
        });
      }
    }
  };
};

export const generateBhPredictions = options => {
  return async dispatch => {
    let { sessionId, userId, groupId } = options;
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-phq-gad-predictions`;
    let payload = options?.payload || {};
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    try {
      let response = await post(url, payload);
      if (response?.data) {
        if (typeof response.data === "string") {
          throw new Error("Error generating behavioural predictions, please try again");
        }
        dispatch({
          type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA",
          data: response.data,
          sessionId: options?.sessionId,
        });
        await dispatch(saveBhPredictions({ ...options, bhPredictions: response.data }));
      }
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating behavioural predictions, please try again", {
          position: "bottom-left",
        });
      }
    }
  };
};

export const saveBhPredictions = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let { sessionId, groupId, userId, bhPredictions, patientId, notesId } = options;
    let url = "practitioner-role/session-member-notes/save-behavioural-health-predictions";
    let payload = {
      id: notesId,
      sessionId,
      groupId,
      userId,
      behaviouralHealthPredictions: bhPredictions,
      patientId,
      practitionerRoleId,
    };
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    try {
      let response = await post(url, payload);
      if (response?.id) {
        dispatch({
          type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_ID",
          notesId: response?.id,
          sessionId: options?.sessionId,
        });
      }
      dispatch({
        type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA",
        data: bhPredictions || null,
        sessionId: options?.sessionId,
      });
      toast.success("Behavioural Predictions Saved", { position: "bottom-left" });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } catch (error) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    }
  };
};

export const generateNarrative = options => {
  return async (dispatch, getState) => {
    let { sessionId, userId, groupId } = options;
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-narrative`;
    let payload = options?.payload || {};
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    try {
      let response = await post(url, payload);
      if (response?.data) {
        // dispatch({
        //   type: "ADD_SESSION_DETAILS_NARRATIVE_DATA",
        //   data: response.data,
        // });
        await dispatch(saveNarrativeData({ ...options, narrativeData: response.data }));
      }
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating narrative, please try again", {
          position: "bottom-left",
        });
      }
    }
  };
};

export const saveNarrativeData = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let { sessionId, groupId, userId, soapNote, patientId, narrativeData } = options;
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
      show: true,
      sessionId: options?.sessionId,
    });
    const { soapNotesJsonData, notesId } = await dispatch(
      getSessionMemberNotes({ sessionId, patientId, noAction: true })
    );
    let jsonSoapNote = soapNotesJsonData ? { ...soapNotesJsonData } : {};
    if (narrativeData) {
      jsonSoapNote = {
        ...jsonSoapNote,
        narrative: narrativeData,
      };
    }
    let url = "practitioner-role/session-member-notes/save-soap-notes";
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let payload = {
      id: notesId,
      sessionId,
      groupId,
      userId,
      soapNote,
      jsonSoapNote,
      patientId,
      practitionerRoleId,
    };
    try {
      let response = await post(url, payload);
      dispatch({
        type: "ADD_SESSION_DETAILS_NARRATIVE_DATA",
        data: response?.data?.jsonSoapNote?.narrative,
        sessionId: options?.sessionId,
      });
      if (response?.id) {
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_ID",
          notesId: response?.id,
          sessionId: options?.sessionId,
        });
      }
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
        data: response?.data?.jsonSoapNote || null,
        sessionId: options?.sessionId,
      });
      toast.success("Narrative Saved", { position: "bottom-left" });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    } catch (error) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
        sessionId: options?.sessionId,
      });
    }
  };
};

export const reEnrollSpeakerVoicePrint = async options => {
  let { sessionData, providerSessionId, speakerIdInDiarizedTranscript, speakerLabel } = options;
  let url = `practitioner-role/meeting-session/speaker-audio-workflow/re-enroll-speaker-audio`;
  let payload = {
    providerSessionId,
    patientId: "",
    practitionerRoleId: "",
    speakerIdInDiarizedTranscript,
    speakerLabel: speakerLabel.toLowerCase(),
  };
  if (speakerLabel.toLowerCase() === "patient") {
    payload.patientId = sessionData.patientId;
  } else if (speakerLabel.toLowerCase() === "provider") {
    payload.practitionerRoleId = sessionData.practitionerRoleId;
  }
  try {
    await post(url, payload);
    toast.success(`${toTitleCase(speakerLabel)} voice print enrolled successfully`);
  } catch (error) {
    console.log("error", error);
  }
};

export const saveSpeakerMapping = options => {
  return async dispatch => {
    let { sessionData, providerSessionsTranscriptData, sessionId, sessionCategory } = options;
    dispatch({
      type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
      data: providerSessionsTranscriptData,
      sessionId: options?.sessionId,
    });
    let patientVoiceFound = false;
    let providerVoiceFound = false;
    dispatch({
      type: "TOGGLE_SAVE_SPEAKER_MAPPING_LOADER",
      sessionId,
      show: true,
    });
    let promises = [];
    for (const providerSession of providerSessionsTranscriptData) {
      try {
        let providerSessionId = providerSession.providerSessionId;
        let transcriptData = providerSession.transcriptData;
        let csvData = [];
        for (const transcript of transcriptData) {
          let timestamp = Number(transcript.timestamp);
          let providerParticipantId = transcript.providerParticipantId;
          let providerPeerId = transcript.providerPeerId;
          let memberId = transcript.memberId;
          let memberName = transcript.memberName;
          let transcriptText = transcript.transcriptText;
          if (
            memberName.toLowerCase() === "patient" &&
            !patientVoiceFound &&
            sessionCategory === "individual"
          ) {
            patientVoiceFound = true;
            promises.push(
              reEnrollSpeakerVoicePrint({
                sessionData,
                providerSessionId,
                speakerIdInDiarizedTranscript: providerParticipantId.replace("Speaker ", ""),
                speakerLabel: "patient",
              })
            );
          }
          if (
            memberName.toLowerCase() === "provider" &&
            !providerVoiceFound &&
            sessionCategory === "individual"
          ) {
            providerVoiceFound = true;
            promises.push(
              reEnrollSpeakerVoicePrint({
                sessionData,
                providerSessionId,
                speakerIdInDiarizedTranscript: providerParticipantId.replace("Speaker ", ""),
                speakerLabel: "provider",
              })
            );
          }
          csvData.push([
            timestamp,
            providerParticipantId,
            providerPeerId,
            memberId,
            memberName,
            transcriptText,
          ]);
        }
        let csv = Papa.unparse(csvData);
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvFile = new File([blob], "session-transcripts-from-audio");
        let url = `practitioner-role/meeting-session/group/transcript-from-audio/upload?sessionId=${sessionId}&providerSessionId=${providerSessionId}`;
        if (sessionCategory === "individual") {
          url = `practitioner-role/meeting-session/individual-session/transcript-from-audio/upload?individualSessionId=${sessionId}&providerSessionId=${providerSessionId}`;
        }
        let payload = new FormData();
        payload.append("file", csvFile);
        promises.push(
          post(url, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        );
        try {
          await Promise.allSettled(promises);
        } catch (error) {
          console.log(error);
        }
        dispatch({
          type: "TOGGLE_SAVE_SPEAKER_MAPPING_LOADER",
          sessionId,
          show: false,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "TOGGLE_SAVE_SPEAKER_MAPPING_LOADER",
          sessionId,
          show: false,
        });
      }
    }
  };
};

let voicePrintCheckStarted = [];

export const getSessionSpeakersVoicePrint = options => {
  return async dispatch => {
    if (voicePrintCheckStarted.includes(options.sessionId)) {
      return;
    }
    voicePrintCheckStarted.push(options.sessionId);
    let providerSessionsTranscriptData = options.providerSessionsTranscriptData || [];
    let sessionId = options?.sessionId;
    let sessionData = options.sessionData;
    let initSpeakerProfileVoicePrintCheckPromises = [];
    let allSpeakersEnrolled = false;
    let totalSpeakers = 0;
    providerSessionsTranscriptData.forEach(providerSession => {
      let providerSessionId = providerSession.providerSessionId;
      let speakers =
        providerSession.transcriptData?.map(transcript =>
          transcript.providerParticipantId?.replace("Speaker ", "")
        ) || [];
      totalSpeakers = totalSpeakers + speakers.length;
      speakers.forEach(speaker => {
        initSpeakerProfileVoicePrintCheckPromises.push(
          dispatch(getSpeakerVoicePrint(sessionId, providerSessionId, speaker, true))
        );
      });
    });
    try {
      let responses = await Promise.allSettled(initSpeakerProfileVoicePrintCheckPromises);
      let speakerProfileVoicePrintList = responses
        .map(response => {
          return response.value || null;
        })
        .filter(i => !!i);
      if (totalSpeakers === speakerProfileVoicePrintList.length) {
        allSpeakersEnrolled = true;
      }
    } catch (error) {
      console.log("error", error);
    }
    if (
      !allSpeakersEnrolled &&
      options?.sessionCategory === "individual" &&
      checkTodaySession(sessionData)
    ) {
      let speakerProfileVoicePrintCheckPromises = [];
      providerSessionsTranscriptData.forEach(providerSession => {
        let providerSessionId = providerSession.providerSessionId;
        let speakers =
          providerSession.transcriptData?.map(transcript => transcript.providerParticipantId) || [];
        speakers.forEach(speaker => {
          speakerProfileVoicePrintCheckPromises.push(
            dispatch(getSpeakerVoicePrint(sessionId, providerSessionId, speaker))
          );
        });
      });
      try {
        await Promise.allSettled(speakerProfileVoicePrintCheckPromises);
      } catch (error) {
        console.log("error", error);
      }
    }
  };
};

const speakerVoicePrintLoading = {};

export const getSpeakerVoicePrint = (
  sessionId,
  providerSessionId,
  speakerId,
  noRetry = false,
  prevAttempt = 0
) => {
  return async (dispatch, getState) => {
    let state = getState();
    let voicePrintEnrolled =
      state?.session?.sessionDetails?.[sessionId]?.[providerSessionId]?.[speakerId]
        .voicePrintEnrolled;
    let voicePrintLoading =
      speakerVoicePrintLoading[`${sessionId}-${providerSessionId}-${speakerId}`];
    if (!!voicePrintEnrolled || !!voicePrintLoading) {
      return null;
    }
    speakerVoicePrintLoading[`${sessionId}-${providerSessionId}-${speakerId}`] = true;
    const maxRetries = 30;
    let attempt = prevAttempt;
    if (noRetry) {
      attempt = maxRetries;
    }
    const retryStatusCheck = async () => {
      if (attempt >= maxRetries) {
        return null;
      }
      attempt++;
      console.log(`Retrying (${attempt}/${maxRetries})...`);
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(dispatch(getSpeakerVoicePrint(providerSessionId, speakerId, false, attempt)));
        }, 10000); // Retry after 10 seconds
      });
    };
    let url = `practitioner-role/meeting-session/provider-session-id/${providerSessionId}/speaker-audio-extraction-results-from-audio/speaker/${speakerId}`;
    try {
      let response = await get(url);
      let voicePrintFileUrl = response?.data || null;
      if (voicePrintFileUrl) {
        try {
          const speakerProfileEnrollmentFileContent = await fetch(voicePrintFileUrl, {
            headers: {
              Range: "bytes=0-1000", // Adjust the range if needed
            },
          });
          let speakerProfileEnrollmentStatus = null;
          if (speakerProfileEnrollmentFileContent) {
            let audioDuration = await getAudioDuration(speakerProfileEnrollmentFileContent);
            speakerProfileEnrollmentStatus = {
              sessionId,
              providerSessionId,
              speakerId,
              voicePrintFileUrl,
              voicePrintEnrolled: true,
              audioDuration,
            };
            dispatch({
              type: "ADD_SESSION_DETAILS_SPEAKER_VOICE_PRINT_DATA",
              data: speakerProfileEnrollmentStatus,
              sessionId: sessionId,
              providerSessionId,
              speakerId,
            });
            return speakerProfileEnrollmentStatus;
          } else {
            if (noRetry) {
              return null;
            }
            return await retryStatusCheck();
          }
        } catch (error) {
          if (noRetry) {
            return null;
          }
          return await retryStatusCheck();
          // console.log("error", error);
        }
      } else {
        if (noRetry) {
          return null;
        }
        return await retryStatusCheck();
      }
    } catch (error) {
      if (noRetry) {
        return null;
      }
      return await retryStatusCheck();
    }
  };
};

const getAudioDuration = async fileContent => {
  try {
    const arrayBuffer = await fileContent.arrayBuffer();
    const dataView = new DataView(arrayBuffer);

    // Validate the file format: Check "RIFF" and "WAVE"
    const riff = dataView.getUint32(0, true); // "RIFF"
    const wave = dataView.getUint32(8, true); // "WAVE"
    if (riff !== 0x46464952 || wave !== 0x45564157) {
      throw new Error("Not a valid WAV file.");
    }

    // Read Byte Rate at offset 28
    const byteRate = dataView.getUint32(28, true); // Bytes per second
    if (!byteRate) {
      throw new Error("Invalid byte rate in WAV header.");
    }

    // Traverse chunks to find the "data" chunk
    let offset = 12; // Start after "RIFF" and "WAVE"
    let dataChunkSize = 0;

    while (offset < dataView.byteLength) {
      const chunkID = dataView.getUint32(offset, true); // Chunk ID (4 bytes)
      const chunkSize = dataView.getUint32(offset + 4, true); // Chunk size (4 bytes)

      if (chunkID === 0x61746164) {
        // "data" in ASCII
        dataChunkSize = chunkSize;
        break;
      }

      // Move to the next chunk (8 bytes for ID and size + chunkSize)
      offset += 8 + chunkSize;
    }

    if (!dataChunkSize) {
      throw new Error("Data chunk not found in WAV file.");
    }

    // Calculate duration
    return dataChunkSize / byteRate; // In seconds
  } catch (error) {
    console.log("error", error);
    return 0;
  }
};

const initState = {
  session: {
    data: null,
    loading: false,
  },
  memberNotes: {
    data: {},
    loading: false,
    updateLoading: false,
  },
  soapNotes: {
    data: "",
    notesId: "",
    loading: false,
    soapNotesJson: null,
  },
  narrative: {
    data: "",
    loading: false,
  },
  bhPredictions: {
    data: null,
    notesId: "",
    loading: false,
  },
  sessionTranscript: {
    data: [],
    loading: false,
  },
  treatmentPlan: {
    data: null,
    loading: false,
  },
  speakerProfileEnrollment: {
    loading: false,
    data: [
      // {
      //   speakerType: "Provider",
      //   practitionerRoleId: "2d2f00dd-946e-4158-b4d9-2d6b2d9cfd7e",
      //   speakerProfileEnrolled: true,
      //   speakerProfileEnrollmentFileUrl: "test",
      // },
    ],
  },
  speakerRecognition: {
    loading: false,
    data: {},
  },
  sessionDetailsData: {},
};

const ACTION_HANDLERS = {
  ["TOGGLE_SESSION_DETAILS_SESSION_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          session: {
            ...((state.sessionDetailsData[action.sessionId] || {}).session || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_SESSION_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          session: {
            ...((state.sessionDetailsData[action.sessionId] || {}).session || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_MEMBER_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          memberNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).memberNotes || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_MEMBER_NOTES"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          memberNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).memberNotes || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_UPDATE_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          memberNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).memberNotes || {}),
            updateLoading: action.show,
          },
        },
      },
    };
  },
  ["UPDATE_SESSION_DETAILS_MEMBER_NOTES"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          memberNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).memberNotes || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["TOGGLE_SAVE_SPEAKER_MAPPING_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          saveSpeakerMappingLoading: action.show,
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          sessionTranscript: {
            ...((state.sessionDetailsData[action.sessionId] || {}).sessionTranscript || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_TRANSCRIPT_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          sessionTranscript: {
            ...((state.sessionDetailsData[action.sessionId] || {}).sessionTranscript || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_TRANSCRIPT_SPEAKER_MAPPING_STATUS"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          sessionTranscript: {
            ...((state.sessionDetailsData[action.sessionId] || {}).sessionTranscript || {}),
            speakerMapped: action.flag,
          },
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          soapNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).soapNotes || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_SOAP_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          soapNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).soapNotes || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_SOAP_NOTES_ID"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          soapNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).soapNotes || {}),
            notesId: action.notesId,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          soapNotes: {
            ...((state.sessionDetailsData[action.sessionId] || {}).soapNotes || {}),
            soapNotesJson: action.data,
          },
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          bhPredictions: {
            ...((state.sessionDetailsData[action.sessionId] || {}).bhPredictions || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          bhPredictions: {
            ...((state.sessionDetailsData[action.sessionId] || {}).bhPredictions || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_BH_PREDICTIONS_ID"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          bhPredictions: {
            ...((state.sessionDetailsData[action.sessionId] || {}).bhPredictions || {}),
            notesId: action.notesId,
          },
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          treatmentPlan: {
            ...((state.sessionDetailsData[action.sessionId] || {}).treatmentPlan || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_TREATMENT_PLAN_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          treatmentPlan: {
            ...((state.sessionDetailsData[action.sessionId] || {}).treatmentPlan || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          narrative: {
            ...((state.sessionDetailsData[action.sessionId] || {}).narrative || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_NARRATIVE_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          narrative: {
            ...((state.sessionDetailsData[action.sessionId] || {}).narrative || {}),
            data: action.data,
          },
        },
      },
    };
  },
  ["TOGGLE_SPEAKER_PROFILE_ENROLLMENT_STATUS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          speakerProfileEnrollment: {
            ...((state.sessionDetailsData[action.sessionId] || {}).speakerProfileEnrollment || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SPEAKER_PROFILE_ENROLLMENT"]: (state, action) => {
    const sessionId = action.sessionId;
    let speakerDataAdded = false;
    let speakerProfileEnrollmentData = (
      state.sessionDetailsData[sessionId]?.speakerProfileEnrollment?.data || []
    ).map(speaker => {
      if (
        (speaker.speakerType === "Patient" &&
          action.data.speakerType === "Patient" &&
          speaker.patientId === action.data.patientId) ||
        (speaker.speakerType === "Provider" &&
          action.data.speakerType === "Provider" &&
          speaker.practitionerRoleId === action.data.practitionerRoleId)
      ) {
        speakerDataAdded = true;
        return action.data;
      }
      return speaker;
    });

    if (!speakerDataAdded) {
      speakerProfileEnrollmentData.push(action.data);
    }
    console.log("speakerProfileEnrollmentData", speakerProfileEnrollmentData);

    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [sessionId]: {
          ...(state.sessionDetailsData[sessionId] || {}),
          speakerProfileEnrollment: {
            ...((state.sessionDetailsData[sessionId] || {}).speakerProfileEnrollment || {}),
            data: speakerProfileEnrollmentData,
          },
        },
      },
    };
  },
  ["TOGGLE_SPEAKER_RECOGNITION_STATUS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          speakerRecognition: {
            ...((state.sessionDetailsData[action.sessionId] || {}).speakerRecognition || {}),
            loading: action.show,
          },
        },
      },
    };
  },
  ["ADD_SPEAKER_RECOGNITION_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          speakerRecognition: {
            ...((state.sessionDetailsData[action.sessionId] || {}).speakerRecognition || {}),
            data: {
              ...(state.sessionDetailsData[action.sessionId]?.speakerRecognition?.data || {}),
              [action.sessionId]: state.sessionDetailsData[action.sessionId]?.speakerRecognition
                ?.data?.[action.sessionId]
                ? {
                    ...state.sessionDetailsData[action.sessionId].speakerRecognition.data[
                      action.sessionId
                    ],
                    [action.providerSessionId]: state.sessionDetailsData[action.sessionId]
                      .speakerRecognition.data[action.sessionId][action.providerSessionId]
                      ? {
                          ...state.sessionDetailsData[action.sessionId].speakerRecognition.data[
                            action.sessionId
                          ][action.providerSessionId],
                          [action.speakerId]: action.data,
                        }
                      : {
                          [action.speakerId]: action.data,
                        },
                  }
                : {
                    [action.providerSessionId]: {
                      [action.speakerId]: action.data,
                    },
                  },
            },
          },
        },
      },
    };
  },
  ["ADD_SESSION_DETAILS_SPEAKER_VOICE_PRINT_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetailsData: {
        ...state.sessionDetailsData,
        [action.sessionId]: {
          ...(state.sessionDetailsData[action.sessionId] || {}),
          speakerVoicePrint: {
            ...((state.sessionDetailsData[action.sessionId] || {}).speakerVoicePrint || {}),
            data: {
              ...(state.sessionDetailsData[action.sessionId]?.speakerVoicePrint?.data || {}),
              [action.providerSessionId]: state.sessionDetailsData[action.sessionId]
                ?.speakerVoicePrint?.data?.[action.providerSessionId]
                ? {
                    ...state.sessionDetailsData[action.sessionId].speakerVoicePrint.data[
                      action.providerSessionId
                    ],
                    [action.speakerId]: action.data,
                  }
                : {
                    [action.speakerId]: action.data,
                  },
            },
          },
        },
      },
    };
  },
};

export default function sessionDetails(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
