import React, { useEffect, useMemo, useState } from "react";
import { MemberAvatar, Loader, SecondaryButton, TertiaryButton } from "@/components";
import VoicePrintIcon from "@/assets/voice-print.svg";
import VoicePrintCapturedIcon from "@/assets/voice-print-captured.svg";
import EditIcon from "@/assets/edit-icon.svg";
import "./Transcript.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Transcript(props) {
  const [transcriptCopied, setTranscriptCopied] = useState(false);
  const providerName = `${props.sessionData?.practitionerFirstName || ""}${
    props.sessionData?.practitionerLastName ? " " : ""
  }${props.sessionData?.practitionerLastName || ""}`;
  const patientName = `${props.sessionData?.patientFirstName || ""}${
    props.sessionData?.patientLastName ? " " : ""
  }${props.sessionData?.patientLastName || ""}`;
  const providerSessionsTranscriptData = props.data || [];
  const transcriptData = useMemo(
    () =>
      providerSessionsTranscriptData.reduce((acc, providerSessionsTranscript) => {
        const sessionTranscripts = providerSessionsTranscript.transcriptData.map(i => {
          return {
            ...i,
            providerSessionId: providerSessionsTranscript.providerSessionId,
          };
        });
        return acc.concat(sessionTranscripts);
      }, []),
    [providerSessionsTranscriptData]
  );
  const copyTranscript = () => {
    let transcriptText = "";
    transcriptData.forEach(transcript => {
      let memberName = transcript.memberName;
      if (
        ["provider", "patient"].includes(memberName?.toLowerCase()) &&
        props.sessionCategory === "individual"
      ) {
        if (memberName?.toLowerCase() === "provider" && providerName) {
          memberName = providerName;
        }
        if (memberName?.toLowerCase() === "patient" && patientName) {
          memberName = patientName;
        }
      }
      transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
    });
    navigator.clipboard
      .writeText(transcriptText)
      .then(() => {
        setTranscriptCopied(true);
        setTimeout(() => {
          setTranscriptCopied(false);
        }, 3000);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  return (
    <div className="session-transcript-container">
      {!props.compareTranscript && (
        <>
          <div className="session-transcript-header">
            <div className="session-transcript-title">Transcript</div>
            <div className="session-transcript-actions">
              {/* {(props.selectedUserRole?.businessFunction === "Management and Leadership" ||
            props.sessionCategory === "group") &&
            transcriptData.length > 0 && (
              <SecondaryButton onClick={() => props.setSpeakerMappingOpen(true)}>
                Map Speakers
              </SecondaryButton>
            )} */}
              <SecondaryButton onClick={copyTranscript}>
                {transcriptCopied ? "Transcript Copied" : "Copy Transcript"}
              </SecondaryButton>
            </div>
          </div>
          {!props.speakerProfileEnrollment?.loading && !props.speakerRecognition?.loading && (
            <SpeakerMappingError
              sessionCategory={props.sessionCategory}
              speakerVoicePrint={props.speakerVoicePrint}
              sessionData={props.sessionData}
              speakerProfileEnrollment={props.speakerProfileEnrollment}
              speakerRecognition={props.speakerRecognition}
              sessionId={props.sessionId}
              setSpeakerMappingOpen={props.setSpeakerMappingOpen}
              selectedUserRole={props.selectedUserRole}
            />
          )}
        </>
      )}
      <Loader loading={props.loading || props.saveSpeakerMappingLoading}>
        {transcriptData.length > 0 ? (
          <div className="session-transcript-content">
            {transcriptData.map((transcript, index) => {
              let memberId = transcript.memberId;
              let providerParticipantId = transcript.providerParticipantId;
              let providerSessionId = transcript.providerSessionId;
              let memberName = transcript.memberName;
              if (
                memberId === providerParticipantId &&
                props.speakerRecognition?.data?.[props.sessionId]?.[providerSessionId]?.[
                  memberId
                ] &&
                props.sessionCategory === "group"
              ) {
                const { predictedLabel = "", speakerUUID = "" } =
                  props.speakerRecognition?.data?.[props.sessionId]?.[providerSessionId]?.[
                    providerParticipantId
                  ];
                if (predictedLabel && speakerUUID) {
                  if (predictedLabel.toLowerCase() === "provider") {
                    memberName = "Provider";
                  } else {
                    memberId = speakerUUID;
                    let sessionPatientMemberDtos =
                      props.sessionData?.sessionPatientMemberDtos || [];
                    let patientObj = sessionPatientMemberDtos.find(
                      i => i.patientId === speakerUUID
                    );
                    if (patientObj) {
                      memberName = `${patientObj.patientFirstName || ""} ${
                        patientObj.patientLastName || ""
                      }`;
                    }
                  }
                }
              }
              let baseTimeStamp = transcriptData?.[0].timestamp;
              let seconds = transcript.timestamp - baseTimeStamp;
              let transcriptTime = formatTime(seconds / 1000);

              let transcriptText = transcript.transcriptText;
              let isProvider =
                memberName === "Provider" ||
                memberId === props.sessionData?.practitionerRoleId ||
                memberId === "Provider";
              let isVoicePrintCaptured = false;
              let voiceUrl = "";
              if (!isProvider) {
                if (props.sessionCategory === "individual") {
                  let patientId = props.sessionData?.patientId;
                  let enrollmentStatus = props.speakerProfileEnrollment?.data?.find(
                    i => i.speakerType === "Patient" && i.patientId === patientId
                  );
                  if (enrollmentStatus) {
                    isVoicePrintCaptured = !!enrollmentStatus.speakerProfileEnrolled;
                    voiceUrl =
                      props.speakerVoicePrint?.data?.[providerSessionId]?.[
                        providerParticipantId.replace("Speaker ", "")
                      ]?.voicePrintFileUrl;
                  }
                } else {
                  let enrollmentStatus = props.speakerProfileEnrollment?.data?.find(
                    i => i.speakerType === "Patient" && i.patientId === memberId
                  );
                  if (enrollmentStatus) {
                    isVoicePrintCaptured = !!enrollmentStatus.speakerProfileEnrolled;
                    voiceUrl =
                      props.speakerVoicePrint?.data?.[providerSessionId]?.[
                        providerParticipantId.replace("Speaker ", "")
                      ]?.voicePrintFileUrl;
                  }
                }
              } else {
                let practitionerRoleId = props.sessionData?.practitionerRoleId;
                let enrollmentStatus = props.speakerProfileEnrollment?.data?.find(
                  i => i.speakerType === "Provider" && i.practitionerRoleId === practitionerRoleId
                );
                if (enrollmentStatus) {
                  isVoicePrintCaptured = !!enrollmentStatus.speakerProfileEnrolled;
                  voiceUrl =
                    props.speakerVoicePrint?.data?.[providerSessionId]?.[
                      providerParticipantId.replace("Speaker ", "")
                    ]?.voicePrintFileUrl;
                }
              }
              if (
                ["provider", "patient"].includes(memberName?.toLowerCase()) &&
                props.sessionCategory === "individual"
              ) {
                if (memberName?.toLowerCase() === "provider" && providerName) {
                  memberName = providerName;
                }
                if (memberName?.toLowerCase() === "patient" && patientName) {
                  memberName = patientName;
                }
              }
              return (
                <div
                  className={`transcript-item`}
                  key={`${transcript.timestamp}-${transcript.memberId}-${convertStringToHash(
                    transcriptText
                  )}-${index}`}
                >
                  <div className="transcript-item-header">
                    <div className="transcript-avatar">
                      {/* <MemberAvatar
                        name={memberName}
                        size={"sm"}
                      /> */}
                      {memberName}
                    </div>
                    {!props.compareTranscript && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Edit Speaker Mapping</Tooltip>}
                        >
                          <img
                            className="edit-icon"
                            src={EditIcon}
                            alt=""
                            onClick={() => props.setSpeakerMappingOpen(true)}
                          />
                        </OverlayTrigger>
                        {props.enableVoiceRecognitionFeature && (
                          <div className="voice-enrollment-status">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {isVoicePrintCaptured
                                    ? props.sessionCategory === "individual"
                                      ? "Voice Print Captured"
                                      : "Speaker recognized"
                                    : props.sessionCategory === "individual"
                                    ? "Voice print not captured"
                                    : "Speaker not recognized"}
                                </Tooltip>
                              }
                            >
                              {isVoicePrintCaptured ? (
                                <a
                                  target="_blank"
                                  href={voiceUrl}
                                >
                                  <img
                                    src={
                                      isVoicePrintCaptured ? VoicePrintCapturedIcon : VoicePrintIcon
                                    }
                                    className="voice-enrollment-icon"
                                    alt=""
                                  />
                                </a>
                              ) : (
                                <img
                                  src={
                                    isVoicePrintCaptured ? VoicePrintCapturedIcon : VoicePrintIcon
                                  }
                                  className="voice-enrollment-icon"
                                  alt=""
                                />
                              )}
                            </OverlayTrigger>
                          </div>
                        )}
                      </>
                    )}
                    <div className="transcript-time">{transcriptTime}</div>
                  </div>
                  <div className="transcript-text">{transcriptText}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-transcript-container">
            No Transcripts Found. Please check back again later.
          </div>
        )}
      </Loader>
    </div>
  );
}

export default Transcript;

const convertStringToHash = str => {
  if (!str) {
    return "";
  }
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

const SpeakerMappingError = props => {
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowError(true);
    }, 3000);
  }, []);
  const totalUniqueSpeakers = useMemo(() => {
    let uniqueSpeakers = new Set();
    if (props.speakerVoicePrint?.data) {
      Object.keys(props.speakerVoicePrint.data).forEach(providerSessionId => {
        Object.keys(props.speakerVoicePrint.data[providerSessionId]).forEach(speakerId => {
          uniqueSpeakers.add(speakerId);
        });
      });
    }
    return uniqueSpeakers.size;
  }, [props.speakerVoicePrint]);
  const totalGroupParticipants = useMemo(() => {
    const patientsLength = props.sessionData?.sessionPatientMemberDtos?.length || 0;
    return patientsLength + 1;
  }, [props.sessionData]);
  const totalRecognisedSpeakers = useMemo(() => {
    let uniqueSpeakers = [];
    if (props.speakerRecognition?.data?.[props.sessionId]) {
      Object.keys(props.speakerRecognition.data[props.sessionId]).forEach(providerSessionId => {
        Object.keys(props.speakerRecognition.data[props.sessionId][providerSessionId]).forEach(
          speaker => {
            if (
              props.speakerRecognition.data[props.sessionId][providerSessionId][speaker].speakerUUID
              //   &&
              // !uniqueSpeakers.includes(
              //   props.speakerRecognition.data[props.sessionId][providerSessionId][speaker]
              //     .speakerUUID
              // )
            ) {
              uniqueSpeakers.push(
                props.speakerRecognition.data[props.sessionId][providerSessionId][speaker]
                  .speakerUUID
              );
            }
          }
        );
      });
    }
    return uniqueSpeakers.length;
  }, [props.speakerRecognition]);
  const smallAudioSpeakers = useMemo(() => {
    let count = 0;
    if (props.speakerVoicePrint?.data) {
      Object.keys(props.speakerVoicePrint.data).forEach(providerSessionId => {
        Object.keys(props.speakerVoicePrint.data[providerSessionId]).forEach(speaker => {
          if (props.speakerVoicePrint.data[providerSessionId][speaker]?.audioDuration < 30) {
            count = count + 1;
          }
        });
      });
    }
    return count;
  }, [props.speakerVoicePrint]);
  let errorMessage = "";
  if (props.sessionCategory === "group") {
    if (totalRecognisedSpeakers < totalGroupParticipants) {
      const unrecognisedSpeakers = totalUniqueSpeakers - totalRecognisedSpeakers;
      if (smallAudioSpeakers > 0) {
        errorMessage = `We couldn't recognise ${smallAudioSpeakers} speaker${
          smallAudioSpeakers > 1 ? "s" : ""
        } as the audio recorded for those speakers is less than 30 seconds. Please map those speakers manually.`;
      } else if (unrecognisedSpeakers > 0) {
        console.log("unrecognisedSpeakers", unrecognisedSpeakers);
        console.log("totalUniqueSpeakers", totalUniqueSpeakers);
        console.log("totalRecognisedSpeakers", totalRecognisedSpeakers);
        errorMessage = `We couldn't recognise ${unrecognisedSpeakers} speaker${
          unrecognisedSpeakers > 1 ? "s" : ""
        } due to some issue. Please map those speakers manually.`;
      }
    } else if (totalUniqueSpeakers > totalGroupParticipants) {
      errorMessage =
        "We have identified more speakers than the number of group participants. Please review the speaker mapping to ensure the correct speakers are aligned with the group participants.";
    }
  }
  if (props.sessionCategory === "individual" && totalUniqueSpeakers > 2) {
    errorMessage =
      "We have identified more than 2 speakers in the room. Please review the speaker mapping to correctly identify the patient or remove any additional individuals from the room.";
    if (props.selectedUserRole?.organizationId === "dad3db72-1f0e-4de5-b82a-27bc03c586d3") {
      errorMessage = ""; // disabling this error for Soulside Demo Org;
    }
  }
  if (!errorMessage || !showError) {
    return <></>;
  }
  return (
    <div className="speaker-mapping-prompt">
      <div className="speaker-mapping-prompt-text">{errorMessage}</div>
      <TertiaryButton onClick={() => props.setSpeakerMappingOpen(true)}>
        Review Speaker Mapping
      </TertiaryButton>
    </div>
  );
};
