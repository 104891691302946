import { useState } from "react";
import { get, rawGet } from "@/services/api";
import Papa from "papaparse";
import { set } from "lodash";

const useAmazonTranscribe = () => {
  const [providerSessionId, setProviderSessionId] = useState("");
  const [deepgramTranscriptData, setDeepgramTranscriptData] = useState([]);
  const [amazonTranscribeTranscriptData, setAmazonTranscribeTranscriptData] = useState([]);
  const [deepgramTranscriptLoading, setDeepgramTranscriptLoading] = useState(false);
  const [amazonTranscribeTranscriptLoading, setAmazonTranscribeTranscriptLoading] = useState(false);
  const [sessionDetailsData, setSessionDetailsData] = useState(null);
  const [sessionDetailsLoading, setSessionDetailsLoading] = useState(false);
  const getSessionDetails = async ({ sessionId, sessionCategory }) => {
    let url = `practitioner-role/sessions/find-by-id/${sessionId}`;
    if (sessionCategory === "individual") {
      url = `practitioner-role/individual-session/find-by-id/${sessionId}`;
    }
    setSessionDetailsLoading(true);
    let sessionDetails = null;
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data || null;
        sessionDetails = data;
      }
    } catch (error) {}
    setSessionDetailsData(sessionDetails);
    setSessionDetailsLoading(false);
  };
  const getProviderSession = async providerSessionId => {
    let url = `meeting-provider/meeting-session/${providerSessionId}`;
    try {
      let response = await rawGet(url);
      if (response?.data) {
        let data = response.data || null;
        return data?.[0] || null;
      }
    } catch (error) {
      return null;
    }
  };
  const getSessionTranscript = async () => {
    if (!providerSessionId) {
      return;
    }
    const providerSession = await getProviderSession(providerSessionId);
    if (!providerSession) {
      return;
    }
    console.log("providerSession", providerSession);

    const sessionCategory =
      providerSession.sessionCategory === "INDIVIDUAL" ? "individual" : "group";
    const sessionId =
      sessionCategory === "individual"
        ? providerSession.individualSessionId
        : providerSession.sessionId;
    if (!sessionId || !sessionCategory) {
      return;
    }
    const options = {
      sessionId,
      sessionCategory,
    };
    setDeepgramTranscriptLoading(true);
    setAmazonTranscribeTranscriptLoading(true);
    await getSessionDetails(options);
    let deepgramTranscriptData = [];
    let amazonTranscribeTranscriptData = [];
    try {
      const providerSessionsList = await getProviderSessions(options);
      let providerSessionTranscriptPromiseList = [];
      let amazonTranscribeProviderSessionTranscriptPromiseList = [];
      providerSessionsList
        .filter(i => i.providerSessionId === providerSessionId)
        .forEach(providerSession => {
          let providerSessionTranscriptPromise = getProviderSessionTranscript({
            ...options,
            session: providerSession,
          });
          let amazonProviderSessionTranscriptPromise = getProviderSessionTranscript({
            ...options,
            session: providerSession,
            transcriptionProvider: "amazon-transcribe",
          });
          providerSessionTranscriptPromiseList.push(providerSessionTranscriptPromise);
          amazonTranscribeProviderSessionTranscriptPromiseList.push(
            amazonProviderSessionTranscriptPromise
          );
        });
      try {
        let providerSessionTranscriptPromiseResponses = await Promise.allSettled(
          providerSessionTranscriptPromiseList
        );
        let amazonTranscribeProviderSessionTranscriptPromiseResponses = await Promise.allSettled(
          amazonTranscribeProviderSessionTranscriptPromiseList
        );
        if (providerSessionTranscriptPromiseResponses?.length > 0) {
          providerSessionTranscriptPromiseResponses.forEach(providerSessionTranscriptResponse => {
            if (providerSessionTranscriptResponse?.value?.transcriptData?.length > 0) {
              let providerTranscriptData =
                providerSessionTranscriptResponse.value.transcriptData || [];
              let providerSessionId = providerSessionTranscriptResponse.value.providerSessionId;
              let providerSession = providerSessionTranscriptResponse.value.providerSession;
              deepgramTranscriptData.push({
                providerSessionId,
                providerSession,
                transcriptData: providerTranscriptData,
              });
            }
          });
        }
        if (amazonTranscribeProviderSessionTranscriptPromiseResponses?.length > 0) {
          amazonTranscribeProviderSessionTranscriptPromiseResponses.forEach(
            providerSessionTranscriptResponse => {
              if (providerSessionTranscriptResponse?.value?.transcriptData?.length > 0) {
                let providerTranscriptData =
                  providerSessionTranscriptResponse.value.transcriptData || [];
                let providerSessionId = providerSessionTranscriptResponse.value.providerSessionId;
                let providerSession = providerSessionTranscriptResponse.value.providerSession;
                amazonTranscribeTranscriptData.push({
                  providerSessionId,
                  providerSession,
                  transcriptData: providerTranscriptData,
                });
              }
            }
          );
        }
      } catch (error) {
        console.error("Error fetching provider sessions transcripts:", error);
      }
    } catch (error) {
      console.error("Error fetching provider sessions:", error);
    }
    setDeepgramTranscriptData(deepgramTranscriptData);
    setAmazonTranscribeTranscriptData(amazonTranscribeTranscriptData);
    setDeepgramTranscriptLoading(false);
    setAmazonTranscribeTranscriptLoading(false);
  };
  const getProviderSessions = async options => {
    let url =
      options?.sessionCategory === "individual"
        ? `practitioner-role/meeting-session/individual-session/${options?.sessionId}/list/reconciled-provider-sessions`
        : `practitioner-role/meeting-session/group/${options?.sessionId}/list/reconciled-provider-sessions`;
    try {
      let response = await get(url);
      let providerSessionsList = response?.data || [];
      providerSessionsList = providerSessionsList.sort(
        (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
      );
      return providerSessionsList;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const getProviderSessionTranscript = async options => {
    let { session, sessionCategory, transcriptionProvider } = options;
    let providerSessionTranscriptData = null;
    let sessionId =
      sessionCategory === "individual" ? session?.individualSessionId : session?.sessionId;
    let providerSessionId = session?.providerSessionId;
    let url =
      sessionCategory === "individual"
        ? `practitioner-role/meeting-session/individual-session/${sessionId}/provider-session-id/${providerSessionId}/${
            transcriptionProvider === "amazon-transcribe"
              ? "transcript-from-audio-from-amazon-transcribe"
              : "transcript-from-audio"
          }`
        : `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/${
            transcriptionProvider === "amazon-transcribe"
              ? "transcript-from-audio-from-amazon-transcribe"
              : "transcript-from-audio"
          }`;
    try {
      let response = await get(url);
      if (response?.data) {
        let transcriptUrl = response.data;
        let pdfPromise = new Promise((resolve, reject) => {
          let resultData = null;
          Papa.parse(transcriptUrl, {
            download: true,
            complete: function (results) {
              let data = results?.data || [];
              let transcriptData = data
                .filter(i => !!i && !!i[3])
                .map(i => {
                  return {
                    timestamp: Number(i[0]),
                    providerParticipantId: i[1],
                    providerPeerId: i[2],
                    memberId: i[3],
                    memberName: i[4],
                    transcriptText: i[5],
                  };
                })
                .sort((a, b) => a.timestamp - b.timestamp);
              resultData = {
                transcriptData,
                csvData: data,
                providerSessionId,
                providerSession: session,
                sessionId,
              };
              if (transcriptData?.length > 0) {
                resolve(resultData);
              } else {
                reject(resultData);
              }
            },
            error: function () {
              reject(resultData);
            },
          });
        });
        try {
          providerSessionTranscriptData = await pdfPromise;
        } catch (error) {
          console.log(error, providerSessionId);
        }
      } else {
        providerSessionTranscriptData = null;
      }
    } catch (error) {
      console.log("error", error, providerSessionId);
      providerSessionTranscriptData = null;
    }
    return providerSessionTranscriptData;
  };
  return {
    providerSessionId,
    setProviderSessionId,
    deepgramTranscriptData,
    amazonTranscribeTranscriptData,
    deepgramTranscriptLoading,
    amazonTranscribeTranscriptLoading,
    getSessionTranscript,
    sessionDetailsData,
    sessionDetailsLoading,
  };
};

export default useAmazonTranscribe;
