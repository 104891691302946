import SessionDetails from "./SessionDetails";
import { connect } from "react-redux";
import {
  getMemberNotes,
  updateMemberNotes,
  getSessionMemberNotes,
  generateSoapNotes,
  saveSoapNotes,
  generateBhPredictions,
  saveBhPredictions,
  saveSpeakerMapping,
  getSessionDetails,
  getSessionDetailsTranscript,
  generateTreatmentPlan,
  generateNarrative,
  saveNarrativeData,
  getSessionSpeakerProfilesEnrollmentStatus,
  getSessionSpeakerRecognitionStatus,
  getSessionSpeakersVoicePrint,
} from "./sessionDetailsModule";

import { getGroups } from "../Groups/groupsModule";

import { getPatients } from "../Patients/patientsModule";

import { removeTask } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    memberNotes: state.sessionDetails.memberNotes,
    soapNotes: state.sessionDetails.soapNotes,
    narrative: state.sessionDetails.narrative,
    bhPredictions: state.sessionDetails.bhPredictions,
    sessionTranscript: state.sessionDetails.sessionTranscript,
    session: state.sessionDetails.session,
    treatmentPlan: state.sessionDetails.treatmentPlan,
    speakerProfileEnrollment: state.sessionDetails.speakerProfileEnrollment,
    speakerRecognition: state.sessionDetails.speakerRecognition,
    sessionDetailsData: state.sessionDetails.sessionDetailsData,
    patients: state.patients.patients,
    groups: state.groups.groups,
    preferredTimezone: state.user.preferredTimezone,
    selectedUserRole: state.user.selectedUserRole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMemberNotes: options => dispatch(getMemberNotes(options)),
    updateMemberNotes: options => dispatch(updateMemberNotes(options)),
    getSessionMemberNotes: options => dispatch(getSessionMemberNotes(options)),
    generateSoapNotes: options => dispatch(generateSoapNotes(options)),
    saveSoapNotes: options => dispatch(saveSoapNotes(options)),
    generateBhPredictions: options => dispatch(generateBhPredictions(options)),
    saveBhPredictions: options => dispatch(saveBhPredictions(options)),
    getPatients: options => dispatch(getPatients(options)),
    saveSpeakerMapping: options => dispatch(saveSpeakerMapping(options)),
    getGroups: options => dispatch(getGroups(options)),
    getSessionDetails: options => dispatch(getSessionDetails(options)),
    getSessionDetailsTranscript: options => dispatch(getSessionDetailsTranscript(options)),
    generateTreatmentPlan: options => dispatch(generateTreatmentPlan(options)),
    generateNarrative: options => dispatch(generateNarrative(options)),
    saveNarrativeData: options => dispatch(saveNarrativeData(options)),
    removeTask: sessionId => dispatch(removeTask(sessionId)),
    getSessionSpeakerProfilesEnrollmentStatus: options =>
      dispatch(getSessionSpeakerProfilesEnrollmentStatus(options)),
    getSessionSpeakerRecognitionStatus: options =>
      dispatch(getSessionSpeakerRecognitionStatus(options)),
    getSessionSpeakersVoicePrint: options => dispatch(getSessionSpeakersVoicePrint(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetails);
